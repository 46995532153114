<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-tree-table
          title="자재분류 목록"
          parentProperty="upMaterialTypeCd"
          customID="materialTypeCd"
          :columns="gridType.columns"
          :data="gridType.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <q-form ref="editForm">
          <c-card title="자재분류 상세정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addType" />
                <c-btn v-if="editable && dataeditable" label="LBLREMOVE" icon="remove" @btnClicked="delType" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="materialTypeData"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveTypes"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  label="자재분류 명칭"
                  name="materialTypeName"
                  v-model="materialTypeData.materialTypeName">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'selectUpType', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeUpType', color: 'red' }
                  ] : null"
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="상위 자재분류"
                  name="upMaterialTypeName"
                  v-model="materialTypeData.upMaterialTypeName"
                  @selectUpType="() => { isUpTypeOpen = true }"
                  @removeUpType="removeUpType">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  required
                  :editable="editable && dataeditable"
                  label="순번"
                  name="sortOrder"
                  type="number"
                  v-model="materialTypeData.sortOrder">
                </c-text>
              </div>
              <div class="col-6">
                <c-radio
                  :editable="editable && dataeditable"
                  codeGroupCd="MATERIAL_KIND_CD"
                  label="자재구분"
                  name="materialKindCd"
                  v-model="materialTypeData.materialKindCd">
                </c-radio>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <q-dialog
      ref="upTypeDialog"
      persistent
      v-model="isUpTypeOpen">
      <q-card class="menu-card" style="width: 600px">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
          <div class="text-h6">상위 자재분류</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectUpType"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline square color="primary" text-color="white">
                <q-icon name="check"></q-icon>{{upTypeNm}}
              </q-chip>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="uptype-content-material p-2">
          <q-tree
            ref="upTypeTree"
            :nodes="upTypeTree"
            node-key="materialTypeCd"
            label-key="materialTypeName"
            children-key="children"
            no-nodes-label="상위유형이 없습니다."
            no-results-label=""
            :default-expand-all="false"
            selected-color="primary"
            :selected.sync="selectedUpType"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'material-type',
  data() {
    return {
      gridType: {
        columns: [
          {
            name: 'materialTypeName',
            field: 'materialTypeName',
            label: '자재분류',
            align: 'left',
            sortable: false,
          },
          {
            name: 'materialKindName',
            field: 'materialKindName',
            label: '자재구분',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
        height: '362px'
      },
      searchParam: {
        delFlag: 'N',
      },
      materialTypeData: {
        materialTypeCd: '',  // 자재분류 코드
        materialTypeName: '',  // 자재분류명
        upMaterialTypeCd: '',  // 상위자재분류 코드
        materialKindCd: 'MKC0000001',  // 자재구분코드
        sortOrder: '',  // 순번
        delFlag: 'N',  // 삭제여부
      },
      upTypeTree: [],
      upTypeNm: '상위분류를 선택하세요.',
      isUpTypeOpen: false,
      selectedUpType: null,
      selectedSysMenu: {},
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      nameUrl: '',
      saveable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: '',
      saveType: 'POST',
    };
  },
  computed: {
  },
  watch: {
    selectedUpType() {
      if (this.selectedUpType) {
        let data = this.$refs['upTypeTree'].getNodeByKey(this.selectedUpType);
        this.upTypeNm = data.materialTypeName;
      } else {
        this.upTypeNm = '상위분류를 선택하세요.';
      }
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;

      // api scope
      this.listUrl = selectConfig.mdm.mam.material.type.list.url;
      this.saveUrl = transactionConfig.mdm.mam.material.type.insert.url;
      this.detailUrl = selectConfig.mdm.mam.material.type.get.url;
      this.nameUrl = selectConfig.mdm.mam.material.type.name.url;
      this.insertUrl = transactionConfig.mdm.mam.material.type.insert.url;
      this.updateUrl = transactionConfig.mdm.mam.material.type.update.url;
      this.deleteUrl = transactionConfig.mdm.mam.material.type.delete.url;

      // list scope
      this.getList();
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upMaterialTypeCd',
        customID: 'materialTypeCd',
      });
      return tree;
    },
    selectUpType() {
      if (this.selectedUpType) {
        let data = this.$refs['upTypeTree'].getNodeByKey(this.selectedUpType);
        this.materialTypeData.upMaterialTypeCd = data.materialTypeCd;
        this.materialTypeData.upMaterialTypeName = data.materialTypeName;
        this.$refs['upTypeDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '상위 분류를 선택하세요.', // 상위 분류를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeUpType() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '상위분류가 없는 경우 최상위 분류로 들어가게 됩니다.\n\r진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.materialTypeData.upMaterialTypeCd = null;
          this.materialTypeData.upMaterialTypeName = null;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getList() {
      this.reset();
      // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.saveable = false;
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridType.data = _result.data;
        this.upTypeTree = this.convertTree(_result.data);
      },);
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true; 
      this.$http.url = this.detailUrl;
      this.$http.param = {materialTypeCd: row.materialTypeCd};
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.materialTypeData = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addType() {
      this.saveable = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.materialTypeData = {
        materialTypeCd: '',  // 자재분류 코드
        materialTypeName: '',  // 자재분류명
        upMaterialTypeCd: null,  // 상위자재분류 코드
        materialKindCd: 'MKC0000001',  // 자재구분코드
        sortOrder: '',  // 순번
        delFlag: 'N',  // 삭제여부
      };
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        materialTypeCd: '',  // 자재분류 코드
        materialTypeName: '',  // 자재분류명
        upMaterialTypeCd: null,  // 상위자재분류 코드
        materialKindCd: 'MKC0000001',  // 자재구분코드
        sortOrder: '',  // 순번
        delFlag: 'N',  // 삭제여부
      };
    },
    delType() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.materialTypeData.upMaterialTypeCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveTypes() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크 
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
      this.rowClick({ materialTypeCd: result.data.materialTypeCd })
    },
  }
};
</script>
<style lang="sass">
.uptype-content-material
  height: 100%
  max-height: 600px !important
  min-height: 500px !important
  overflow-y: auto
</style>